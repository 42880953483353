export default {
    init() { // JavaScript to be fired on all pages
        //---------------------------------------------------------------------------------------------
        // Accessibility 
        //---------------------------------------------------------------------------------------------
        // Make whole area clickable. Just add the class "clickable-area" on the element 
        //---------------------------------------------------------------------------------------------
        $('.clickable-area').on("click", function(){
            this.target = $(this);

            return $(this.target).find("a").attr("href").length > 1 && ($(this.target).find("a").attr("target") ? window.open($(this.target).find("a").attr("href"),
                $(this.target).find("a").attr("target")) : window.location.href = $(this.target).find("a").attr("href")),
                !1;
        });

        //---------------------------------------------------------------------------------------------
        // Toggle Attribute on all buttons with classname .expander 
        //---------------------------------------------------------------------------------------------
        $("button.expander").on("click", function (){
            if($(this).attr('aria-expanded') === 'false') {
                $(this).attr('aria-expanded', true);
            } else {
                $(this).attr('aria-expanded', false);
            }
        });

 		//---------------------------------------------------------------------------------------------
        // Adds and removes class on reload for animation purpose
        //---------------------------------------------------------------------------------------------
		if ($("#menu-huvudnavigation").hasClass("loaded")) {
			$("#menu-huvudnavigation").removeClass("loaded");
		} else {
			$("#menu-huvudnavigation").addClass("loaded");
		}

		//---------------------------------------------------------------------------------------------
		//MOVE INPUT LABEL ON FOCUS AND VALUE
		//---------------------------------------------------------------------------------------------
		var AllFormInputs = $('.gfield div input, .gfield div textarea');
		AllFormInputs.on('focus', function() {
			$(this).closest('.gfield').find('label').addClass('is-minify');
			$(this).closest('.gfield').addClass('has-minified-label');
		}).on('blur', function() {
		}).on('blur', function() {
			if (!$(this).val()) {
				$(this).closest('.gfield').find('label').removeClass('is-minify');
				$(this).closest('.gfield').removeClass('has-minified-label');
			}
		});

        //---------------------------------------------------------------------------------------------
        // While tabbing through the page, add "tabbed" class to selected element below 
        //---------------------------------------------------------------------------------------------
        $("body").on("keyup", "a, .btn, button, input, textarea, select, .clickable-area", function (e) {
            tabHighlight(e);
        });

        function tabHighlight(e) {
            var target = e.target;
            if (e.which === 9) {
                $(target).addClass('tabbed');
                $(target).closest('.clickable-area').addClass('tabbed');
            }
        }

        //---------------------------------------------------------------------------------------------
        // While tabbing through passed element, remove the class tabbed
        //---------------------------------------------------------------------------------------------
        $("body").on("blur", ".tabbed", function (e) {
            tabHighlightRemove(e);
        });

        function tabHighlightRemove(e) {
            var target = e.target;

            $(target).removeClass('tabbed');
            $(target).closest('.clickable-area').removeClass('tabbed');
        }

        // Responsive menu (Mobile)
        //---------------------------------------------------------------------------
        $('#toggle_navigation').on("click", function(){
            $(this).toggleClass('open');
            $('#mobile_navigation').toggleClass('slide-out');
			$('#header').toggleClass('mobile-menu-toggled');
        });

        // Show submenu, on click
        // ---------------------------------------------------------------------------------------------
        $("button.menu-expand").on("click", function (){
            $(this).toggleClass('is-active');
            $(this).parent("li").children(".submenu").slideToggle(150);
        });

        // LIGHTBOX
        //---------------------------------------------------------------------------
        var togglelb = $('.lb_toggler');
        var lbo      = $('.lb_overlay');
        var clsTrig  = $('.close_lb, .lb_backDrop');
        var lbID;

        //TOGGLE LIGHTBOX
        togglelb.on("click", function(){
            lbID = $(this).attr('data-lb');
            $(this).attr('aria-hidden', false);
            $('#'+lbID).addClass('is-visible');
			$("body").addClass('lb-popped'); 
        });

        //CLOSE LIGHTBOX
        clsTrig.on("click", function(){
            lbo.removeClass('is-visible');
            $('.lb_toggler').attr('aria-hidden', true);
			$("body").removeClass('lb-popped');
        });

        //CLOSE LIGHTBOX WITH ESC KEY
        $(document).on('keydown',function(evt) {
            if(evt.keyCode == 27) {
                if(lbo.hasClass('is-visible')){
                    lbo.removeClass('is-visible');
                    $('.lb_toggler').attr('aria-hidden', true);
                }
            }
        });

        // AJAX function
        //---------------------------------------------------------------------------
        // $(".ajax-call-button").click(function() {    
        //     dbyh_ajax_call();
        // });

        // // AJAX CALL
        // function dbyh_ajax_call() {
        //     // This does the ajax request
        //     $.ajax({
        //         url:    dbyh_ajax.url,
        //         method: "POSt",
        //         data: {
        //             'action': 'dbyh_ajax_call',
        //             'nonce' : dbyh_ajax.nonce
        //         },
        //         success: (data) => {
        //             console.log(data);
        //         },
        //         error: (errorThrown) => {
        //             console.log(errorThrown);
        //         }
        //     });  
        // }

        // Lägg på classer på meny för att docka den vid scroll.
        //---------------------------------------------------------------------------
        // $(window).scroll(function(){
        //     if ($(this).scrollTop() > 100) {
        //         $('#header').addClass('fixed');
        //     } else {
        //         $('#header').removeClass('fixed');
        //     }

        //     if ($(this).scrollTop() > 300) {
        //         $('#header').addClass('vissible');
        //     } else {
        //         $('#header').removeClass('vissible');
        //     }
        // });




        // Possible to toggle admin-sidepanel
        //---------------------------------------------------------------------------
        const adminBody = $('body');
        const adminPanel = $('.dbyh-adminbar');
        const adminPanelBtn = $('.toggle-dbyh-panel');
        adminPanelBtn.on("click", function(){
            if(!adminPanelBtn.hasClass('is-expanded')) {
                adminPanelBtn.addClass('is-expanded');

                adminPanel.addClass('is-expanded');
                adminBody.addClass('is-expanded');
                setCookie("admin-panel-cookies", 'true', 7);
            } else {
                adminPanelBtn.removeClass('is-expanded');
                adminPanel.removeClass('is-expanded');
                adminBody.removeClass('is-expanded');
                deleteCookie("admin-panel-cookies");
            }
        });
        
        const adminPanelItem = $('.toggle-submenu');
        adminPanelItem.hover(function() {
            $( this ).delay(200).addClass('is-active').find('.row-submenu').stop(true, false).slideDown(200);
        }, function() {
            $( this ).delay(200).removeClass('is-active').find('.row-submenu').stop(true, false).slideUp(200);
        });

        // Handle admin-cookie 
        // Adminpanel - Expand or not expand
        //---------------------------------------------------------------------------
        function deleteCookie(name) {  
            setCookie(name, '', -1); 
        }

        function setCookie(name, value, days) {
            var d = new Date;
            d.setTime(d.getTime() + 24*60*60*1000*days);
            document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
        }

    }, finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
