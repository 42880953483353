import './util/jqueryLoad';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import blog from './routes/blog';

/** Populate Router instance with DOM routes */
const routes = new Router({
    common,           // All pages
    home,             // Home page
    blog,            // Blogg page
});

// Load Events
$(() => routes.loadEvents());
